.fade-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .fade-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }

.collapse-enter-active {
  animation: ExpandMore 1s ease-out forwards;
}

.collapse-leave-active {
  animation: ExpandLess 0.3s ease-out forwards
}

@keyframes ExpandMore {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  50% {
    opacity: 1;
    transform: translateY(2%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes ExpandLess {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 0.4;
    transform: translateY(50%);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}



